import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";

function EmptyBubble({ width, height, strokeColor, setActive, index }) {
  const StyledSvg = styled("svg")`
    margin: 0 8px;
    cursor: pointer;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      margin: 0 12px;
    }
  `;
  return (
    <StyledSvg
      width={width || "16px"}
      height={height || "16px"}
      viewBox="0 0 18 18"
      onClick={() => {
        setActive(index);
      }}
    >
      <g
        id="Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Components---Fact-Module"
          transform="translate(-792.000000, -5853.000000)"
          className={strokeColor || "white-stroke"}
          strokeWidth="2"
        >
          <g
            id="Carousel-dots-Copy-6"
            transform="translate(749.000000, 5862.000000) rotate(-90.000000) translate(-749.000000, -5862.000000) translate(741.000000, 5802.000000)"
          >
            <circle id="Oval-5" cx="8" cy="112" r="8"></circle>
          </g>
        </g>
      </g>
    </StyledSvg>
  );
}

EmptyBubble.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColor: PropTypes.string,
  setActive: PropTypes.func,
  index: PropTypes.number,
};

export default EmptyBubble;
