import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";

const FilledBubble = ({
  width,
  height,
  fillColor,
  strokeColor,
  setActive,
  index,
}) => {
  const StyledSvg = styled("svg")`
    margin: 0 8px;
    cursor: pointer;
  `;
  return (
    <StyledSvg
      width={width || "22px"}
      height={height || "22px"}
      viewBox="0 0 18 18"
      onClick={() => {
        setActive(index);
      }}
    >
      <g
        id="Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Components---Fact-Module"
          transform="translate(-688.000000, -5853.000000)"
          className={
            fillColor
              ? `${fillColor} ${strokeColor}`
              : "white-fill white-stroke"
          }
        >
          <g
            id="Carousel-dots-Copy-6"
            transform="translate(749.000000, 5862.000000) rotate(-90.000000) translate(-749.000000, -5862.000000) translate(741.000000, 5802.000000)"
          >
            <circle id="Oval-1-filled-in" cx="8" cy="8" r="8"></circle>
          </g>
        </g>
      </g>
    </StyledSvg>
  );
};

FilledBubble.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  setActive: PropTypes.func,
  index: PropTypes.number,
};

export default FilledBubble;
