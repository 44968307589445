import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";

import { theme } from "../../utils/global-styles/theme";

const BenefitsHero = ({
  className,
  children,
  heroImage,
  acumenBenefitsObject,
}) => {
  const HeroGrid = styled.div`
      display: grid;
      grid-template-rows: 611px 100px 376px;
      grid-template-columns: repeat(3, 1fr);
      font-family: ${theme.fontFaces.headers};

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-template-rows: 511px 100px 1fr;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-rows: 511px 50px 1fr;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template-rows: 370px 50px 1fr;
      }
    `,
    HeroImg = styled(Img)`
      grid-area: 1 / 1 / span 2 / span 3;
    `,
    HeroImgPlaceholder = styled.div`
      grid-area: 1 / 1 / span 2 / span 3;
    `,
    ImageOverlayTextContainer = styled.div`
      grid-area: 1 / 1 / span 2 / span 3;
      padding: 109px 0px;
      z-index: 1;
      background: linear-gradient(
        350.52deg,
        rgba(50, 50, 50, 0) 0%,
        rgba(50, 50, 50, 0.3) 100%
      );

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 50px 0px;
      }
    `,
    BenefitContainer = styled.div`
      display: flex;
      grid-area: 3 / 1 / span 1 / span 3;
      justify-content: center;
      margin-top: 70px;
      z-index: 2;
      & > *:first-child {
        margin-right: 40px;
      }
      & > *:last-child {
        margin-left: 40px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        flex-direction: column;
        align-items: center;
        margin: 0;
        & > *:first-child {
          margin: 40px;
        }
        & > *:last-child {
          margin-left: 0;
          margin-top: 40px;
        }
      }
    `,
    BenefitCard = styled.div`
      height: 360px;
      width: 360px;
      padding: 45px;
      border-radius: 30px;
      box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1);
      z-index: 2;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        padding: 25px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 40px;
      }
    `,
    BenefitHeading = styled.h2`
      font-weight: bold;
      margin-bottom: 12px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      }
    `,
    BenefitDescription = styled.p`
      font-size: 17px;
      line-height: 1.5;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      }
    `;

  const generateBenefitCard = (benefitsObject, cardNumber) => {
    let marginTop = 150 - 50 * cardNumber;
    let backgroundColor;
    if (cardNumber === 1) {
      backgroundColor = theme.colors.royalBlue;
    } else if (cardNumber === 2) {
      backgroundColor = theme.colors.primary;
    } else if (cardNumber === 3) {
      backgroundColor = theme.colors.secondary;
    }
    return (
      benefitsObject[`benefit${cardNumber}`] && (
        <BenefitCard
          css={css`
            grid-area: 2 / ${cardNumber} / span 2 / span 1;
            background-color: ${backgroundColor};
            @media only screen and (max-width: ${theme.breakpoints.helper800}) {
              grid-area: ${cardNumber + 2} / 1 / span 1 / span 3;
              margin-top: 0;
            }
          `}
        >
          <BenefitHeading>
            {benefitsObject[`benefit${cardNumber}`]}
          </BenefitHeading>
          <BenefitDescription>
            {benefitsObject[`description${cardNumber}`]}
          </BenefitDescription>
        </BenefitCard>
      )
    );
  };

  return (
    <HeroGrid className={className || ""}>
      {heroImage && heroImage.image && heroImage.image.fluid ? (
        <HeroImg
          fluid={heroImage.image.fluid}
          alt={heroImage.imageDescription}
        />
      ) : (
        <HeroImgPlaceholder className="secondary-bg" />
      )}
      <ImageOverlayTextContainer className="white">
        {children}
      </ImageOverlayTextContainer>
      <BenefitContainer>
        {generateBenefitCard(acumenBenefitsObject, 1)}
        {generateBenefitCard(acumenBenefitsObject, 2)}
        {generateBenefitCard(acumenBenefitsObject, 3)}
      </BenefitContainer>
    </HeroGrid>
  );
};

BenefitsHero.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  heroImage: PropTypes.shape({
    image: PropTypes.shape({
      fluid: PropTypes.shape({
        srcSet: PropTypes.string,
        aspectRatio: PropTypes.number,
      }),
    }),
    imageDescription: PropTypes.string,
  }),
  acumenBenefitsObject: PropTypes.shape({
    benefit1: PropTypes.string,
    description1: PropTypes.string,
    benefit2: PropTypes.string,
    description2: PropTypes.string,
    benefit3: PropTypes.string,
    description3: PropTypes.string,
  }),
};

export default BenefitsHero;
