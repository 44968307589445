import React from "react";
import PropTypes from "prop-types";
import ActiveBubble from "../Icons/FilledBubble";
import EmptyBubble from "../Icons/EmptyBubble";

function Bubble({ active, index, setActive, fillColor, strokeColor }) {
  return active ? (
    <ActiveBubble
      index={index}
      setActive={setActive}
      fillColor={fillColor}
      strokeColor={strokeColor}
    />
  ) : (
    <EmptyBubble
      index={index}
      setActive={setActive}
      strokeColor={strokeColor}
    />
  );
}

Bubble.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
  setActive: PropTypes.func,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

export default Bubble;
